<template>
  <tepmplate-block
    mod-class
    content-class="mytechnique-wrap"
    title-page="Новая задача"
  >
    <vuescroll>
      <div class="toggle-forms-wrapper">
        <button @click="showShortForm" :class="shortForm ? 'start' : ''">
          Обычная форма заявки
        </button>
        <button @click="showFullForm">Расширенная форма заявки</button>
      </div>

      <template v-if="!isPageDataLoading">
        <form
          class="small-create-task main-container"
          ref="form"
          v-if="shortForm"
          @submit.prevent="createRequest"
        >
          <div
            v-if="isIntroPassed === 'false'"
            class="intro-tip p-1 w-75 text-center"
          >
            Это первая задача и нужные поля уже заполнены. Давайте нажмем кнопку
            "Создать задачу"
          </div>

          <label class="tip-anchor">
            Название задачи
            <input
              v-model="title"
              type="text"
              :class="{ 'over-top': isIntroPassed === 'false' }"
            />
          </label>
          <label>
            <div>Исполнитель</div>
            <!--    <b-select
              v-model="selectedEmployee"
              class="request-form__input form-select"
            >
              <option :value="undefined">Персональный менеджер</option>
              <option v-for="item in employees" :key="item.id" :value="item.id">
                {{ item.value }}
              </option>
            </b-select> -->

            <div class="position-relative">
              <b-input
                :disabled="isIntroPassed === 'false'"
                style="marginTop:5px"
                type="text"
                v-model="employeeSearch"
                @focus="
                  showEmployees = true;
                  employeeSearch = '';
                "
                @blur="hideEmployees"
                placeholder="Введите исполнителя"
              />
              <div
                class="position-absolute optgroup employees"
                v-if="showEmployees"
              >
                <div
                  v-for="item in sortedEmployeeByInput"
                  :key="item.id"
                  class="optgroup_item"
                  @click.prevent="selectEmployee(item)"
                >
                  {{ item.value }}
                </div>

                <!--  <span v-if="!sortedEmployeeByInput.length">Не найдено</span> -->
              </div>
            </div>
          </label>
          <label>
            Описание задачи
            <textarea
              v-model="texts['desc']"
              :class="{ 'over-top': isIntroPassed === 'false' }"
              placeholder="Опишите задачу"
            />

            <div v-if="file" class="attached d-flex justify-content-between">
              <div class="attached__name">{{ file.name }}</div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                @click="file = null"
                class="attached__icon"
              >
                <path
                  d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z"
                  fill="#fb5717"
                />
              </svg>
            </div>
            <label v-else for="file-modal" class="attach-label mt-3">
              <img
                width="32px"
                src="/resource/img/attach.ae11ead1.svg"
                alt=""
              />
              Вложение
              <b-form-file
                v-model="file"
                class="mt-3 file"
                id="file-modal"
                plain
              />
            </label>
          </label>
          <label>
            Приоритет
            <b-form-select
              class="choose"
              v-model="priority"
              :options="orderPriorityOptions"
              value-field="value"
              text-field="text"
            ></b-form-select>
          </label>
          <!-- <label>
            Хэштеги
            <b-select class="choose">
              <option>kisdf</option>
              <option>123</option>
            </b-select>
          </label> -->
          <div v-if="spinner" class="spinner">
            <b-spinner />
          </div>
          <button
            v-else
            class="create-task"
            :class="{ 'over-top': isIntroPassed === 'false' }"
          >
            Создать задачу
          </button>
        </form>

        <form
          class="full-create-tack"
          ref="form"
          v-else
          @submit.prevent="createRequest"
        >
          <label>
            <span>Объект постановки задачи</span>
            <img
              src="../../resource/img/question_form.svg"
              alt="question sign"
            />
            <br />
            {{ this.$route.params.title }}
          </label>
          <label>
            Название задачи
            <input type="text" v-model="title" />
          </label>
          <label>
            Тип задачи
            <img
              class="required"
              src="../../resource/img/required_form.svg"
              alt="required sign"
            />
            <img
              src="../../resource/img/question_form.svg"
              alt="question sign"
            />
            <b-select v-model="selectedType" class="choose" required>
              <option v-for="type in types" :key="type.id" :value="type">
                {{ type.title }}
              </option>
            </b-select>
          </label>
          <label>
            Исполнитель
            <img
              class="required"
              src="../../resource/img/required_form.svg"
              alt="required sign"
            />
            <!-- <b-select v-model="selectedEmployee" class="choose">
              <option :value="undefined">Персональный менеджер</option>
              <option v-for="item in employees" :key="item.id" :value="item.id">
                {{ item.value }}
              </option>
            </b-select> -->

            <!-- @input="checkedSearchEmployee" -->

            <div class="position-relative">
              <b-input
                style="marginTop:5px"
                type="text"
                v-model="employeeSearch"
                @focus="
                  showEmployees = true;
                  employeeSearch = '';
                "
                @blur="hideEmployees"
                placeholder="Введите исполнителя"
              />
              <div
                class="position-absolute optgroup employees"
                v-if="showEmployees"
              >
                <div
                  v-for="item in sortedEmployeeByInput"
                  :key="item.id"
                  class="optgroup_item"
                  @click.prevent="selectEmployee(item)"
                >
                  {{ item.value }}
                </div>

                <!--  <span v-if="!sortedEmployeeByInput.length">Не найдено</span> -->
              </div>
            </div>
          </label>
          <label>
            Наблюдатель
            <img
              src="../../resource/img/question_form.svg"
              alt="question sign"
            />
            <!-- <b-select v-model="selectedObservers" class="choose">
              <option :value="null"></option>
              <option :value="undefined">Персональный менеджер</option>
              <option v-for="item in employees" :key="item.id" :value="item.id">
                {{ item.value }}
              </option>
            </b-select> -->
            <div class="position-relative">
              <b-input
                style="marginTop:5px"
                type="text"
                v-model="observerSearch"
                @focus="
                  showObservers = true;
                  observerSearch = '';
                "
                @blur="hideObservers"
                placeholder="Введите наблюдателя"
              />
              <div
                class="position-absolute optgroup employees"
                v-if="showObservers"
              >
                <div
                  v-for="item in sortedObserverByInput"
                  :key="item.id"
                  class="optgroup_item"
                  @click.prevent="selectObserver(item)"
                >
                  {{ item.value }}
                </div>

                <!--  <span v-if="!sortedEmployeeByInput.length">Не найдено</span> -->
              </div>
            </div>
          </label>
          <label>
            <div>Крайний срок выполнения задачи</div>
            <b-input
              class="request-form__input form-select"
              type="datetime-local"
              v-model="deadline"
            />
          </label>
          <label>
            <div>Краткое описание проблемы</div>
            <b-input
              v-model="texts['shortDesc']"
              placeholder="Например, “заменить картридж"
              class="request-form__input"
            />
          </label>
          <!--  <label>
            Описание задачи
            <textarea placeholder="Опишите задачу" v-model="texts['Описание']" />
             <button class="investment" @click.prevent>Вложение</button>
          </label>-->

          <label>
            Описание задачи
            <textarea v-model="texts['desc']" placeholder="Опишите задачу" />

            <div v-if="file" class="attached d-flex justify-content-between">
              <div class="attached__name">{{ file.name }}</div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                @click="file = null"
                class="attached__icon"
              >
                <path
                  d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z"
                  fill="#fb5717"
                />
              </svg>
            </div>
            <label v-else for="file-modal" class="attach-label mt-3">
              <img
                width="32px"
                src="/resource/img/attach.ae11ead1.svg"
                alt=""
              />
              Вложение
              <b-form-file
                v-model="file"
                class="mt-3 file"
                id="file-modal"
                plain
              />
            </label>
          </label>

          <label>
            Критерий выполнения задачи
            <input
              placeholder="Например, “ При печати отсутствуют блеклые участки ”"
              type="text"
              v-model="texts['condition']"
            />
          </label>
          <label>
            Бюджет задачи
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="lbl">Рубли:</div>
                <b-form-input
                  type="number"
                  v-model="budget"
                  class="ml-1 budget-input"
                ></b-form-input>
              </div>
              <div class="d-flex align-items-center">
                <div class="lbl">Часы:</div>
                <b-form-input
                  type="number"
                  v-model="estimatedTime"
                  class="ml-1 budget-input"
                ></b-form-input>
              </div>
            </div>
          </label>
          <!--   <div class="connection">
            <div class="connection__text">Создать связь с другой задачей</div>
            <div class="connection__image">
              <img src="../../resource/img/dots.svg" />
            </div>
          </div> -->
          <label>
            Приоритет
            <b-form-select
              class="choose"
              v-model="priority"
              :options="orderPriorityOptions"
              value-field="value"
              text-field="text"
            ></b-form-select>
          </label>
          <div v-if="spinner" class="spinner">
            <b-spinner />
          </div>
          <button
            v-else
            class="create-task"
            :class="{ 'over-top': isIntroPassed === 'false' }"
          >
            Создать задачу
          </button>
        </form>
      </template>
      <div class="d-flex justify-content-center mb-5 mt-5" v-else>
        <b-spinner />
      </div>
    </vuescroll>

    <div v-if="isIntroPassed === 'false'" class="dark-intro-background double">
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <!-- <div v-if="isIntroPassed === 'false'" class="intro-tip p-2 w-25 text-center">
          Это первая задача и нужные поля уже заполнены. Давайте нажмем кнопку
          "Создать задачу"
        </div> -->
      </div>
    </div>
  </tepmplate-block>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import { OrdersApi, CompanyApi } from "../../function/request/apiV2";
import RequestManager from "../../function/request/RequestManager";
// import { user } from "../../utils/users";
import vuescroll from "vuescroll";
// import axios from "axios";
import { OldChatClass } from "@/classes/Chat";
import { ORDER_PRIORITY_OPTIONS } from "@/app-config";
import { doPoseTip } from "@/utils";

export default {
  name: "OrdersCreate",
  components: { TepmplateBlock, vuescroll },

  data() {
    return {
      employeeSearch: "",
      observerSearch: "",
      showEmployees: false,
      showObservers: false,
      employee_id: undefined,
      observer_id: null,
      spinner: false,
      shortForm: true,
      isLoading: false,
      isPageDataLoading: false,
      ordersApi: new OrdersApi(),
      companyApi: new CompanyApi(),
      title: "",
      file: null,
      problemDescription: "",
      taskDescription: "",
      selectedType: "1",
      statuses: [],
      types: [],
      employees: [],
      employeesObservers: [],
      devices: [],
      observers: [],
      selectedEmployee: undefined,
      selectedObservers: null,
      isShowFullForm: false,
      selectedOfficeId: null,
      selectedDeviceId: null,
      budget: 0,
      budgetChoose: ["Рубли", "Часы", "Человеко-часы"],
      estimatedTime: 0,
      deadline: null,
      tagIds: ["123", "123"],
      chat: null,
      allUsers: [],
      isEmployeesListLoading: false,
      priority: 1,
      orderPriorityOptions: ORDER_PRIORITY_OPTIONS,

      texts: {
        desc: "",
        shortDesc: "",
        condition: ""
      },
      allCompanies: []
    };
  },
  watch: {
    //  selectedEmployee() {}
    employeeSearch() {}
  },
  methods: {
    hideEmployees() {
      setTimeout(() => {
        this.showEmployees = false;
      }, 150);
    },

    selectEmployee(item) {
      this.employeeSearch = item.value;
      this.employee_id = item.id ?? this.crmManagerId;
      // console.log(" this.employeeSearch", this.employeeSearch);
      // console.log(" this.employee_id", this.employee_id);
    },

    checkedSearchEmployee() {
      if (!this.employeeSearch) {
        this.employee_id = undefined;
      }
      if (this.employee_id && !this.sortedEmployeeByInput.length) {
        this.employee_id = undefined;
      }
      if (
        this.sortedEmployeeByInput.length === 1 &&
        this.employeeSearch === this.sortedEmployeeByInput[0].value
      ) {
        this.employee_id = this.sortedEmployeeByInput[0].id;
      }
    },

    hideObservers() {
      setTimeout(() => {
        this.showObservers = false;
      }, 150);
    },

    selectObserver(item) {
      this.observerSearch = item.value;
      this.observer_id = item.id ?? this.crmManagerId;
      // console.log(" this.employeeSearch", this.employeeSearch);
      // console.log(" this.employee_id", this.employee_id);
    },

    checkedSearchObserver() {
      if (!this.observerSearch) {
        this.observer_id = undefined;
      }
      if (this.observer_id && !this.sortedObserverByInput.length) {
        this.observer_id = undefined;
      }
      if (
        this.sortedObserverByInput.length === 1 &&
        this.observerSearch === this.sortedObserverByInput[0].value
      ) {
        this.observer_id = this.sortedObserverByInput[0].id;
      }
    },

    async getRootCompanies() {
      const { items } = await this.companyApi.api.listCompanies({
        limit: 100000000,
        crmManagerId: this.currentUser.id
      });
      this.allCompanies = [...[this.mainCompanyInfo], ...items];
    },

    showFullForm() {
      this.shortForm = false;
    },
    showShortForm() {
      this.shortForm = true;
    },
    getBudget(budget) {
      this.budget = +budget;
    },
    showFileModal() {
      this.$bvModal.show("file-modal");
    },
    async getDevices() {
      try {
        this.spinner = true;
        this.devices = [];
        this.selectedDeviceId = null;
        this.isLoading = true;
        const searchParams = {
          office: this.selectedOfficeId
        };
        const { results } = await RequestManager().getAllDevices(searchParams);
        this.devices = results;
      } finally {
        this.spinner = false;
        this.isLoading = false;
      }
    },

    async createRequest() {
      try {
        this.isLoading = true;
        this.spinner = true;
        const responseOrder = await this.ordersApi.api.createOrder(
          this.orderCreateForm
        );

        /* if (this.permissions?.length) {
          await this.ordersApi.api.editOrderPermissions(
            responseOrder.id,
            this.permissions
          );
        } */

        /* await this.ordersApi.api.editOrderReferences(
          responseOrder.id,
          this.references
        ); */

        if (this.file) {
          await new OldChatClass(responseOrder.chatId).sendMessage(
            "",
            this.currentUser.id,
            this.file
          );
        }

        this.$bvToast.toast("Задача успешно создана", {
          variant: "success",
          title: "Успех"
        });

        await this.$router.push({
          name: "Order",
          params: { id: responseOrder.id, mode: "createOrder" }
        });
      } catch (e) {
        this.$bvToast.toast(e.error.message, {
          variant: "danger",
          title: "Ошибка"
        });
        throw new Error(e);
      } finally {
        this.isLoading = false;
        this.spinner = false;
      }
    },

    getFullName(employee) {
      let {
        first_name,
        last_name,
        email,
        firstName,
        lastName,
        companyName,
        id
      } = employee;
      let result = email;
      first_name = first_name || firstName || "";
      last_name = last_name || lastName || "";

      if ((first_name || last_name) && companyName && companyName !== " ") {
        result = `${companyName}${" "}${"-"}${" "}${first_name} ${last_name}`;
      } else {
        result = ` ${result}`;
      }

      return { id: id, value: result };

      //  return user.getFullName(employee);
    },

    async getCompanyUsers() {
      try {
        /* await this.getRootCompanies();
        const { results } = await RequestManager().getUsersList();
        this.employees = results; */
        this.isEmployeesListLoading = true;
        await this.getRootCompanies();
        const allCompaniesIds = this.allCompanies.map(comp =>
          parseInt(comp.id)
        );
        const { results } = await RequestManager().getUsersList();
        this.allUsers = results;
        this.employees = results.filter(
          res =>
            allCompaniesIds.includes(res.company ? res.company : -1) &&
            parseInt(res.id) !== this.currentExecutorId
        );
        this.isEmployeesListLoading = false;
      } catch (e) {
        throw new Error(e);
      } finally {
        // объединение- компании + пользователи в объекте
        this.employees = this.employees.map(item => {
          // console.log("item",item);
          let company = this.allCompanies.find(
            company1 => +item.company == +company1?.id
          );
          if (company) {
            item.companyName = company.brand ? company.brand : "";
          }
          return item;
        });

        // в строчке - объект {id: id , value: value} , где value - компания + имя + отчество
        this.employees = this.employees.map(item => {
          return this.getFullName(item);
        });
      }

      // Сортировка
      if (this.employees && this.employees.length > 1)
        this.employees.sort((a, b) => a.value.localeCompare(b.value));

      this.employees.unshift({ id: undefined, value: "Персональный менеджер" });

      this.employeesObservers = [...this.employees];

      //  this.employeesObservers.unshift({ id: null, value: " " });
    },

    async getStatuses() {
      try {
        this.statuses = await this.ordersApi.api.getOrderStatuses();
      } catch (e) {
        throw new Error(e);
      }
    },

    async getTypes() {
      try {
        this.types = await this.ordersApi.api.getOrderTypes();
        this.types = this.types.reverse();

        let type1 = this.types.find(
          item => item.id == this.$route.params.typeId
        );

        //   console.log("type1", type1);
        if (type1) {
          this.selectedType = type1;
          this.title = type1.title;
        }

        if (this.$route.params.typeId !== "1") {
          const balanceTypeIdx = this.types.findIndex(type => type.id === "1");
          if (balanceTypeIdx > -1) {
            this.types.splice(balanceTypeIdx, 1);
          }
        }

        // console.log("types*************",this.types)
        // console.log("   this.selectedType ",   this.selectedType )
        // console.log("   this.$route.params.typeId ",    this.$route.params.typeId )

        if (this.isIntroPassed === "false") {
          this.selectedType = this.types.find(type => type.id === "5");
          this.title = "Бесплатный выезд мастера";
          this.texts["desc"] =
            "Добрый день! Установил ваше приложение и хочу получить бесплатный выезд вашего сервисного инженера";
        }
      } catch (e) {
        throw new Error(e);
      }
    }
  },
  async mounted() {
    console.log(this.$route.params);
    //   this.selectedEmployee = this.$route.params.id;
    //  this.employee_id = this.$route.params.id;

    //  console.log("this.$route.params.id",this.$route.params.id)
    this.showShortForm();
  },
  computed: {
    sortedEmployeeByInput() {
      // console.log("employees", this.employees);

      let newEmployees = this.employees.filter(el => {
        //  console.log("el", el);
        return el.value
          .toLowerCase()
          .includes(this.employeeSearch.toLowerCase());
      });
      // .sort((a, b) => a.value.localeCompare(b.value));

      // console.log("newEmployees", newEmployees);

      return newEmployees;
    },

    sortedObserverByInput() {
      // console.log("employees", this.employees);

      let newObservers = this.employeesObservers.filter(el => {
        //  console.log("el", el);
        return el.value
          .toLowerCase()
          .includes(this.observerSearch.toLowerCase());
      });
      // .sort((a, b) => a.value.localeCompare(b.value));

      // console.log("newEmployees", newEmployees);

      return newObservers;
    },

    companyId() {
      return this.$store.getters.currentUser?.company;
    },
    currentUser() {
      return this.$store.getters?.currentUser;
    },
    crmManagerId() {
      return this.$store.getters?.mainCompanyInfo?.crm_manager;
    },

    offices() {
      return this.$store.getters?.officeList;
    },

    permissions() {
      /* const observers = this.observers?.map(el => {
        return {
          userId: "" + el,
          roles: ["canRead"]
        };
      });*/

      if (this.observer_id === null) {
        const responsible = {
          //  userId: "" + (this.selectedEmployee ?? this.crmManagerId),
          userId: "" + (this.employee_id ?? this.crmManagerId),
          roles: ["performer"]
        };
        return [responsible];
      } else {
        const observers = {
          // userId: "" + (this.selectedObservers ?? this.crmManagerId),
          userId: "" + (this.observer_id ?? this.crmManagerId),
          roles: ["viewer"]
        };

        const responsible = {
          //   userId: "" + (this.selectedEmployee ?? this.crmManagerId),
          userId: "" + (this.employee_id ?? this.crmManagerId),
          roles: ["performer"]
        };
        return [observers, responsible];
      }
    },

    /* Тут формируется referns() для привязки оборудования к задаче */
    references() {
      console.log("references");
      return {
        devicesIds: this.$route.params.deviceId
          ? [this.$route.params.deviceId.toString()]
          : undefined
      };
    },

    orderCreateForm() {
      return {
        tagIds: [],
        selectedDeviceId: 1,
        companyId: "" + this.companyId,
        title: this.title ?? this.selectedType.id,
        typeId: this.selectedType.id,
        statusId: "2",
        budget: parseFloat(this.budget) ?? undefined,
        estimatedTime: parseFloat(this.estimatedTime) ?? undefined,
        deadline: this.deadline
          ? new Date(this.deadline).toISOString()
          : undefined,
        texts: this.texts,
        permissions: this.permissions,
        references: this.references,
        priority: this.priority
      };
    }
  },

  async created() {
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
    if (this.isIntroPassed === "false") {
      this.title = "Новый клиент - знакомство с менеджером";
      this.texts["desc"] =
        "Это первая тестовая задача, нужна для знакомства клиента со своим персональным менеджером";
    }
    this.mainCompanyInfo = JSON.parse(
      window.localStorage.getItem("mainCompanyInfo")
    );
    this.isPageDataLoading = true;
    await this.getRootCompanies();
    await this.getStatuses();
    await this.getTypes();
    await this.getCompanyUsers();

    if (this.employees.length) {
      this.selectedEmployee = this.employees[0].id;
      this.employeeSearch = this.employees[0].value;
      this.employee_id = this.employees[0].id;
      //    console.log("employee_id",this.employees)
    }

    if (this.$route.params.id) {
      this.selectedEmployee = this.$route.params.id;
      this.employee_id = this.$route.params.id;
      this.employeeSearch = this.employees.find(
        item => item.id == this.$route.params.id
      )
        ? this.employees.find(item => item.id == this.$route.params.id).value
        : "";
    }

    this.isPageDataLoading = false;
    if (this.isIntroPassed === "false") {
      setTimeout(() => doPoseTip(".tip-anchor", "top"), 250);
    }
    //setTimeout(() => doPoseTip(".tip-anchor", "top"), 100);
  }
};
</script>

<style scoped lang="scss">
.full-create-tack {
  width: 500px;
  height: 1290px;
  padding: 55px 75px 58px;
  background: var(--main-card-color);
  border-radius: 10px;
  margin: 130px auto 0 auto;
  border: 3px solid #cad2ef;
}

.connection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.333333px;
    color: #2c698c;
  }
  &__image {
    width: 28px;
    height: 28px;
    background: #d9d9d9;
    border-radius: 3px;
    image {
      width: 100%;
    }
  }
}

.budget {
  display: flex;
  justify-content: space-between;
  position: relative;
  input {
    width: 204px;
    height: 46px;
    background: var(--auth-input-bg);
    border-radius: 4px;
  }
  &__choose {
    width: 134px;
    height: 46px;
    background-color: var(--auth-input-bg);
    border-radius: 4px;
    margin-top: 3px;
    border: none;
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -45px;
  }
}

.start {
  outline: 5px auto -webkit-focus-ring-color;
}

.toggle-forms-wrapper {
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  :first-child {
    margin-right: 20px;
  }
  button {
    width: 220px;
    height: 50px;
    color: var(--main-text-color);
    background: var(--main-card-color);
    border: 1px solid #2c698c;
    border-radius: 4px;
    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
}

//-----------------------------------------------
.small-create-task {
  padding: 55px 75px 58px;
  width: 500px;
  //height: 645px;
  background: var(--main-card-color);
  border-radius: 10px;
  margin: 130px auto 0 auto;
  position: relative;
  z-index: 1001;
  border: 3px solid #cad2ef;
}

label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;
  margin: 20px auto 0 auto;
  .required {
    vertical-align: top;
    margin-right: 4px;
  }
  textarea {
    padding: 8px 14px;
    display: block;
    width: 350px;
    height: 154px;
    border: 1px solid var(--auth-input-bg);
    color: var(--main-text-color);
    background: var(--auth-input-bg);
    border-radius: 4px;
    resize: none;
    outline: none;
    margin-top: 3px;
  }
  input {
    padding: 0 8px;
    display: block;
    width: 350px;
    height: 46px;
    border: 1px solid var(--auth-input-bg);
    color: var(--main-text-color);
    background: var(--auth-input-bg);
    border-radius: 4px;
    outline: none;
    margin-top: 3px;
  }
}

input::placeholder,
textarea::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #9a9a9a;
}

.choose {
  width: 350px;
  height: 46px;
  background-color: var(--auth-input-bg);
  border-radius: 4px;
  margin-top: 3px;
}

.investment {
  width: 96px;
  height: 19px;
  background: #f1f1f3;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.333333px;
  color: #9a9a9a;
  margin-top: 10px;
}

.create-task {
  width: 235px;
  height: 46px;
  display: block;
  margin: 16px auto 0 auto;
  background: #2c698c;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #ffffff;
  border: 3px solid #cad2ef;
}

.spinner {
  width: 235px;
  height: 46px;
  display: block;
  margin: 16px auto 0 auto;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.3px;
}
/////////////////////////
.request-form {
  width: 50%;
  margin: 0 auto;
  background-color: white;
  /*border: 1px black solid;*/
  border-radius: 10px;
  padding: 50px;

  &__input {
    background: #f1f1f3;
  }

  &__textarea {
    height: 150px;
  }

  &__block:not(:first-child) {
    margin-top: 1.5rem;
  }
}

select.form-select {
  -webkit-appearance: menulist;
}
.form-control {
  background-color: var(--auth-input-bg);
  &:focus {
    background-color: var(--auth-input-bg);
    color: var(--main-text-color);
  }
}

.custom-select {
  background-color: var(--auth-input-bg);
  color: var(--main-text-color);
  &:focus {
    background-color: var(--auth-input-bg);
  }
}
.attach-label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  svg {
    user-select: none;
  }
  .file {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    inset: 0;
  }
}
.attached {
  margin-top: 22px;
  &__name {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__icon {
    cursor: pointer;
  }
}

.employees {
  background-color: var(--main-card-color);
  .optgroup_item:hover {
    background-color: rgba(184, 218, 255, 0.5);
  }
}

.optgroup {
  padding: 5px 10px;
  position: absolute;
  width: 100%;
  max-height: 220px;
  border: 1px solid #ced4da;
  z-index: 9;
  background-color: white;
  overflow-y: auto;
  overflow-x: auto;

  &_item {
    cursor: pointer;

    &:hover {
      background-color: #fafafc;
    }
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #3c3c3cc4;
  z-index: 1;
}

.executor {
  padding: 66px 0 82px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 990px;
  height: 347px;
  background: var(--main-card-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  z-index: 1;
  &__wrapper {
    width: 350px;
    margin: 0 auto;
  }
  img {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
  }
  &__input {
    width: 350px;
    height: 46px;
    background: var(--input-executor-bgcolor);
    color: var(--input-executor-color);
    border-radius: 4px;
  }

  .executor__wrapper__button__container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    button {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      background: #2c698c;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #ffffff;
    }
  }
}

.close-img {
  filter: var(--filter-for-svg);
  cursor: pointer;
}

.lbl {
  min-width: 60px;
  width: 60px;
}
.budget-input {
  max-width: 100px;
}
</style>
